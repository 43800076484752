@import "../../style/var.scss";
.HomeV2 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-image: url(../../images/homev2/bg.png);
  background-size: cover;
  background-position: center center;
  color: #ffffff;
  :global {
    .logo {
      position: absolute;
      left: 222px;
      top: 47px;
      width: 129px;
      height: 44px;
      background-image: url(../../images/homev2/logo.png);
      background-size: contain;
      background-repeat: no-repeat;
    }
    .share-wrap {
      position: absolute;
      top: 55px;
      display: flex;
      justify-content: center;
      align-items: center;
      right: 298px;
      .item {
        height: 36px;
        width: 36px;
        margin-right: 32px;
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-block;
        &:last-child {
          margin-right: 0;
        }
        &.share1 {
          background-image: url(../../images/homev2/share1.png);
          &:hover {
            background-image: url(../../images/homev2/share1-hover.png);
          }
        }
        &.share2 {
          background-image: url(../../images/homev2/share2.png);
          &:hover {
            background-image: url(../../images/homev2/share2-hover.png);
          }
        }
        &.share3 {
          background-image: url(../../images/homev2/share3.png);
          &:hover {
            background-image: url(../../images/homev2/share3-hover.png);
          }
        }
        &.share4 {
          background-image: url(../../images/homev2/share4.png);
          &:hover {
            background-image: url(../../images/homev2/share4-hover.png);
          }
        }
      }
    }
    .text1 {
      position: absolute;
      left: 222px;
      bottom: 332px;
      width: 646px;
      height: 48px;
      background-image: url(../../images/homev2/text1.png);
      background-size: contain;
      background-repeat: no-repeat;
    }
    .text2 {
      position: absolute;
      left: 222px;
      bottom: 267px;
      font-size: 45px;
      font-family: Inter;
      font-weight: 400;
      line-height: 22px;
      color: #ffffff;
    }
    .launch-app {
      position: absolute;
      left: 222px;
      bottom: 158px;
      width: 150px;
      height: 50px;
      border-radius: 6px;
      font-size: 16px;
      font-family: Inter;
      font-weight: 500;
      line-height: 22px;
      color: #020202;
      background: rgba(254, 255, 225, 1);
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background: rgba(255, 255, 255, 1);
      }
    }
    .tvl-num {
      position: absolute;
      right: 298px;
      bottom: 154px;
      display: flex;
      color: #ffffff;
      font-family: Inter;
      .item {
        text-align: center;
        &:first-child {
          padding-right: 56px;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            width: 1px;
            height: 26px;
            top: 8px;
            right: 28px;
            background-color: #ffffff;
          }
        }
        .value {
          line-height: 34px;
          font-size: 28px;
          font-weight: 500;
          line-height: 22px;
          margin-bottom: 8px;
        }
        .label {
          line-height: 16px;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }
      }
    }
  }
}
