.mobile {
  position: relative;
  overflow: hidden;
  :global {
    .header {
      line-height: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 68px;
      background: rgba(20, 20, 22, 1);
      padding: 0px 16px 0px 19px;
      .logo {
        width: 130px;
        height: 20px;
        display: inline-block;
        background-image: url(../../images/mobilev2/logo.png);
        background-size: contain;
        background-repeat: no-repeat;
      }
      .share-wrap {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .item {
          height: 19px;
          width: 19px;
          margin-right: 17px;
          background-size: contain;
          background-repeat: no-repeat;
          display: inline-block;
          &:last-child {
            margin-right: 0;
          }
          &.share1 {
            background-image: url(../../images/mobilev2/share1.png);
          }
          &.share2 {
            background-image: url(../../images/mobilev2/share2.png);
          }
          &.share3 {
            background-image: url(../../images/mobilev2/share3.png);
          }
          &.share4 {
            background-image: url(../../images/mobilev2/share4.png);
          }
        }
      }
    }
    .content {
      transform: translateY(-1px);
      background-image: url(../../images/mobilev2/bg.png);
      background-size: cover;
      background-repeat: no-repeat;
      height: 812px - 68px;
      overflow: hidden;
    }
    .text1 {
      margin: 57px auto 44px auto;
      width: 323px;
      height: 24px;
      background-image: url(../../images/mobilev2/text1.png);
      background-size: contain;
      background-repeat: no-repeat;
    }
    .text2 {
      margin: 0px auto 105px auto;
      width: 343px;
      height: 80px;
      font-size: 18px;
      font-family: Inter;
      font-weight: 400;
      line-height: 28px;
      color: #ffffff;
      text-align: center;
    }
    .tvl-num {
      display: flex;
      justify-content: center;
      color: #ffffff;
      width: 256px;
      margin: 0 auto;
      .item {
        text-align: center;
        font-family: Inter;
        &:first-child {
          padding-right: 56px;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            width: 1px;
            height: 26px;
            top: 8px;
            right: 28px;
            background-color: #ffffff;
          }
        }
        .value {
          line-height: 34px;
          font-size: 28px;
          font-weight: 500;
          line-height: 22px;
          margin-bottom: 8px;
        }
        .label {
          line-height: 16px;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }
      }
    }
    .launch-app {
      margin: 53px 16px 0px 16px;
      left: 222px;
      bottom: 158px;
      height: 50px;
      border-radius: 6px;
      font-size: 16px;
      font-family: Inter;
      font-weight: bold;
      line-height: 22px;
      color: #020202;
      background: rgba(254, 255, 225, 1);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
