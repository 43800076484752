@import "./var.scss";
@import "./Open-Sans/Open-Sans.css";
@import "./Inter/Inter.css";
body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.is-pc {
    min-width: $contentW + 2px;
  }
}
* {
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
