@font-face {
  font-family: "Inter";
  src: url("Inter-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Inter";
  src: url("Inter-Regular.ttf") format("truetype");
  font-weight: 400;
}
