@font-face {
  font-family: "Open Sans";
  src: url("OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url("OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Open Sans";
  src: url("OpenSans-Semibold.ttf") format("truetype");
  font-weight: 600;
}
